<div class="back-navigation" appActivitySection="QRView">
  <button class="back" appUserActivityClickTracking="Back">
    ◁ Back to list
  </button>
</div>

<div class="box">
  <ng-container>
    <div class="title">
      {{ title }}
    </div>
    <img [src]="imgSrc" alt="">
    <div class="description">
      {{ description ? description :  "Scan & Play" }}
    </div>
  </ng-container>
</div>
