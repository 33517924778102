<div
  class="tab-bar tab-bar__with-bg"
>
  <div class="tabs" #tabs appActivitySection="TabBar">
    <button
      class="tab"
      [routerLink]="isPlayPage ? '/main/home' : '/main/jack-astors/play'"
      routerLinkActive="active"
      [appUserActivityClickTracking]="isPlayPage ? 'PlayPageClose' : 'PlayPageOpen'"
    >
      <div class="icon">
        <img src="/themes/jack-astors/assets/images/play.svg" alt=""/>
      </div>
      <div class="label" [class.active]="isPlayPage">
        <span>{{ 'tabBar.play' | translate }}</span>
      </div>
    </button>

    <button
      class="tab"
      [routerLink]="isGetRewardedPage ? '/main/home' : '/main/jack-astors/rewarded'"
      routerLinkActive="active"
      [appUserActivityClickTracking]="isGetRewardedPage ? 'GetRewardedPageClose' : 'GetRewardedPageOpen'"
    >
      <div class="icon">
        <img src="/themes/jack-astors/assets/images/get_rewarded.svg" alt=""/>
      </div>
      <div class="label" [class.active]="isGetRewardedPage">
        <span>{{ 'tabBar.getRewarded' | translate }}</span>
      </div>
    </button>

    <button class="tab"
            routerLink='/main/home'
            appUserActivityClickTracking="PageClose"
      >
        <img class="logo" src="/themes/jack-astors/assets/images/logo.svg" alt=""/>
    </button>

    <button
      class="tab"
      [routerLink]="isWatchPage ? '/main/home' : '/main/jack-astors/watch'"
      routerLinkActive="active"
      [appUserActivityClickTracking]="isWatchPage ? 'WatchPageClose' : 'WatchPageOpen'"
    >
      <div class="icon">
        <img src="/themes/jack-astors/assets/images/watch.svg" alt=""/>
      </div>
      <div class="label" [class.active]="isWatchPage">
        <span>{{ 'tabBar.watch' | translate }}</span>
      </div>
    </button>

    <button
      class="tab"
      [routerLink]="isMorePage ? '/main/home' : '/main/jack-astors/more'"
      routerLinkActive="active"
      [appUserActivityClickTracking]="isMorePage ? 'MorePageClose' : 'MorePageOpen'"
    >
      <div class="icon">
        <img src="/themes/jack-astors/assets/images/more.svg" alt=""/>
      </div>
      <div class="label" [class.active]="isMorePage">
        <span>{{ 'tabBar.more' | translate }}</span>
      </div>
    </button>
  </div>
</div>
